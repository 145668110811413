import * as React from 'react'
import { ApolloProvider } from '@apollo/client'
import { client } from 'src/utils/helpers/client'

export const withApolloContext =
  <P extends object>(Component: React.ComponentType<P>): React.FC<P> =>
  (props: P) =>
    (
      <WithApolloContext>
        <Component {...props} />
      </WithApolloContext>
    )

export default function WithApolloContext({
  children,
}: {
  children: React.ReactNode
}) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
