import MyLink from 'src/components/Link'
import { FooterSubMenuItem } from 'types/layout'

export default function FooterSubMenu({ url, title }: FooterSubMenuItem) {
  return (
    <MyLink
      href={url}
      underline="none"
      prefetch={false}
      sx={{
        color: '#fff',
        opacity: 0.65,
        fontSize: {
          xs: 12,
          md: 14,
        },
        lineHeight: {
          xs: '32px',
          md: '34px',
        },
        display: {
          xs: 'block',
          lg: 'inline-block',
        },
      }}
    >
      {title}
    </MyLink>
  )
}
