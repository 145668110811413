import { useState, useEffect, ReactNode } from 'react'
import { Grid, Box, Typography, IconButton, Collapse } from '@mui/material'
import useCurrentMediaQuery from 'src/hooks/useCurrentMediaQuery'
import SubtractIcon from '@icons/Subtract2'
import AddIcon from '@icons/Add'
import Link from 'src/components/Link'

export default function FooterAccordion({
  children,
  title,
  href,
}: {
  title: ReactNode
  href?: string
  children: ReactNode
}) {
  const currentMediaQuery = useCurrentMediaQuery()
  const [expanded, setExpanded] = useState(!['xs'].includes(currentMediaQuery))
  const handleOpen = () => setExpanded(true)
  const handleClose = () => setExpanded(false)
  useEffect(() => {
    setExpanded(!['xs'].includes(currentMediaQuery))
  }, [currentMediaQuery, setExpanded])

  return (
    <Box>
      <Grid container spacing={{ xs: 0, lg: 0.75 }}>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{
              position: 'relative',
              '&:after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                borderBottom: {
                  xs: '1px solid rgb(255,255,255)',
                  md: 'none',
                },
                opacity: 0.1,
              },
              height: {
                xs: 42,
                md: 34,
                lg: 'auto',
              },
            }}
          >
            <Grid item>
              {href ? (
                <Link
                  href={href}
                  sx={{
                    fontSize: {
                      xs: 12,
                      md: 16,
                    },
                    fontWeight: 'bold',
                    color: '#fff',
                  }}
                  underline="none"
                  prefetch={false}
                >
                  {title}
                </Link>
              ) : (
                <Typography
                  sx={{
                    fontSize: {
                      xs: 12,
                      md: 16,
                    },
                    fontWeight: 'bold',
                    color: '#fff',
                  }}
                >
                  {title}
                </Typography>
              )}
            </Grid>
            <Grid item sx={{ display: { xs: 'block', md: 'none' } }}>
              {expanded ? (
                <IconButton
                  disableRipple
                  aria-label={title as string}
                  color="black"
                  onClick={handleClose}
                >
                  <SubtractIcon
                    sx={{
                      color: 'common.white',
                      opacity: 0.65,
                      fontSize: 20.58,
                    }}
                  />
                </IconButton>
              ) : (
                <IconButton
                  disableRipple
                  aria-label={title as string}
                  color="black"
                  onClick={handleOpen}
                >
                  <AddIcon
                    sx={{
                      color: 'common.white',
                      opacity: 0.65,
                      fontSize: 20.58,
                    }}
                  />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={expanded}>{children}</Collapse>
        </Grid>
      </Grid>
    </Box>
  )
}
