// import appApi from '@/utils/appApi'
import { Box, BoxProps, Typography } from '@mui/material'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/system'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import React from 'react'
import FooterAccordion from 'src/layouts/FooterAccordion'
// import FooterCollectionMenu from './FooterCollectionMenu'
import WithApolloContext from 'src/modules/components/WithApolloContext'
import AppContainer from './AppContainer'
import FooterSubMenu from './FooterSubMenu'

const FooterCollectionMenu = dynamic(() => import('./FooterCollectionMenu'))

// import { useTranslation } from 'next-i18next'
const footerMenus = [
  {
    title: 'Support',
    subMenus: [
      {
        url: '/support/product',
        title: 'Product Support',
      },
      {
        url: '/warranty',
        title: 'Warranty Activation',
      },
      {
        url: '/support/request',
        title: 'Request Support',
      },
      {
        url: '/support-policy/warranty-policy',
        title: 'Support Policy',
      },
      // {
      //   url: '/recipes',
      //   title: 'Recipes',
      // },
    ],
  },
  {
    title: 'Explore',
    subMenus: [
      {
        url: '/about-us',
        title: 'About Us',
      },
      {
        url: 'https://www.facebook.com/DreoHome',
        title: 'Follow Us',
        open: true,
      },
      {
        url: '/dreo-affiliate-program',
        title: 'Affiliate Program',
      },
      {
        url: 'https://us.dreo.com/pages/refer-a-friend',
        title: 'Refer a Friend',
      },
    ],
  },
]

const FooterTypography = styled(Typography)(() => ({
  color: '#A7A8AA',
  fontSize: 12,
  lineHeight: '17px',
  fontWeight: 500,
}))

const MyFooterCollectionMenu = React.memo(FooterCollectionMenu)

const Footer = ({
  sx,
  contactUs,
}: BoxProps & { contactUs?: React.ReactNode }) => {
  // 是否显示头部
  // const [showFooter, setFooter] = useState(true)

  // useEffect(() => {
  //   setFooter(!appApi.dreo)
  // }, [])
  return (
    <Box
      component="footer"
      className={'dreo-app-hide'}
      sx={{
        bgcolor: 'enviLight_neutral.main',
        fontSize: '14px',
        position: 'relative',
        zIndex: 1008,
        p: {
          lg: '64px 0',
          md: '48px 0',
          xs: '0 0 59px 0',
        },
        // display: showFooter ? 'block' : 'none',
        ...sx,
      }}
    >
      <WithApolloContext>
        <AppContainer maxWidth="xl">
          <Grid container sx={{ mb: 3 }}>
            {/* collection */}
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                flexBasis: { lg: '20%', xs: '100%' },
              }}
            >
              <MyFooterCollectionMenu handle="dreo-tower-fans" />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                flexBasis: { lg: '20%', xs: '100%' },
              }}
            >
              <MyFooterCollectionMenu handle="dreo-space-heaters" />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                flexBasis: { lg: '20%', xs: '100%' },
              }}
            >
              <MyFooterCollectionMenu handle="dreo-air-purifiers" />
            </Grid>

            {footerMenus.map((item) => (
              <Grid
                key={item.title}
                item
                xs={12}
                md={4}
                sx={{
                  flexBasis: { lg: '20%', xs: '100%' },
                }}
              >
                <FooterAccordion title={item.title}>
                  <Box
                    sx={{
                      py: {
                        xs: 1,
                        md: 0,
                      },
                    }}
                  >
                    {item.subMenus.map((subMenu) => (
                      <Box key={subMenu.title}>
                        <FooterSubMenu {...subMenu} />
                      </Box>
                    ))}
                  </Box>
                </FooterAccordion>
              </Grid>
            ))}
          </Grid>

          <Box sx={{ mb: 1.5 }}>
            <Typography
              sx={{
                fontSize: {
                  xs: 12,
                  md: 16,
                },
                color: '#fff',
                fontWeight: 700,
              }}
            >
              Contact Us
            </Typography>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              spacing={1}
              mb="12px"
              mt="16px"
            >
              {contactUs ? (
                contactUs
              ) : (
                <>
                  <FooterTypography>
                    Address: 377 Valley Rd, Unit #2686, Clifton, NJ 07013
                  </FooterTypography>
                  {/* <FooterTypography sx={{ mx: 1 }}>
                Phone:&nbsp;
                <Typography
                  component="a"
                  href="tel:+1 888 290 1688"
                  sx={{
                    color: '#4A91E8',
                    fontSize: 12,
                    fontWeight: 500,
                  }}
                >
                  +1 888-290-1688
                </Typography>
              </FooterTypography> */}
                  <FooterTypography>
                    Email:&nbsp;
                    <Typography
                      component="a"
                      href="mailto:support@dreo.com"
                      sx={{
                        color: '#4A91E8',
                        fontSize: 12,
                        fontWeight: 500,
                      }}
                    >
                      support@dreo.com
                    </Typography>
                  </FooterTypography>
                </>
              )}
            </Stack>

            <Divider sx={{ borderColor: '#fff', opacity: 0.1 }} />
          </Box>

          <Stack
            direction={{ xs: 'column', md: 'row' }}
            divider={
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  borderColor: '#fff',
                  opacity: 0.1,
                  display: { xs: 'none', md: 'block' },
                }}
              />
            }
            spacing={{ xs: 1, md: 2 }}
          >
            <FooterTypography>Copyright © 2024.Dreo</FooterTypography>
            <Link
              href="/terms-of-service"
              prefetch={false}
              passHref
              legacyBehavior
            >
              <Box component="a">
                <FooterTypography>Terms of Service</FooterTypography>
              </Box>
            </Link>
            <Link
              href="/privacy-policy"
              prefetch={false}
              passHref
              legacyBehavior
            >
              <Box component="a">
                <FooterTypography>Privacy Policy</FooterTypography>
              </Box>
            </Link>
            <Link
              href="/product-security-policy"
              prefetch={false}
              passHref
              legacyBehavior
            >
              <Box component="a">
                <FooterTypography>Product Security Policy</FooterTypography>
              </Box>
            </Link>
          </Stack>
        </AppContainer>
      </WithApolloContext>
    </Box>
  )
}

export default Footer
