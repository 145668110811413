import { ApolloClient, InMemoryCache } from '@apollo/client'

/**
 * Apollo client实例
 */
export const client = new ApolloClient({
  uri: process.env.NEXT_PUBLIC_SHOPIFY_API_URL,
  cache: new InMemoryCache(),
  headers: {
    'X-Shopify-Storefront-Access-Token': process.env
      .NEXT_PUBLIC_SHOPIFY_ACCESS_TOKEN as string,
  },
  defaultOptions: {
    watchQuery: {
      nextFetchPolicy(currentFetchPolicy) {
        if (
          currentFetchPolicy === 'network-only' ||
          currentFetchPolicy === 'cache-and-network'
        ) {
          // Demote the network policies (except "no-cache") to "cache-first"
          // after the first request.
          return 'cache-first'
        }
        // Leave all other fetch policies unchanged.
        return currentFetchPolicy
      },
    },
  },
})

export default client
